import React, {useState} from 'react';
import withLocation from '../components/withLocation';

const ItsMe = () => {
    const [password, setPassword] = useState('');
    const [verified, setVerified] = useState(false);
    const [error, setError] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        if (process.env.GATSBY_TOKEN === password) {
            setVerified(true);
            setError(false);
        } else {
            setVerified(false);
            setError('NOPE');
        }
    };

    return (
        <div>
            <form onSubmit={(event) => handleSubmit(event)}>
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <input type="submit" value="Submit" />
            </form>
            {verified && (
                <div>
                    <p>{`🥑: ${process.env.GATSBY_SECRET_KEY}`}</p>
                    <p>{`🧚🏻: ${process.env.GATSBY_SECRET_KEY_TWO}`}</p>
                    <p>{`✉️: ${process.env.GATSBY_EMAIL}`}</p>
                    <p>M1704616363</p>
                    <p>S17632731378</p>
                    <p>K1624192488</p>
                </div>
            )}
            {error && <p>{error}</p>}
        </div>
    );
};

export default withLocation(ItsMe);
